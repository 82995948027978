.GameImages{
  width: 100%;

  min-height: 70%;
  max-height: 70%;
  
  border-radius: 10px;

  background-color: var(--background-light);
  padding: 7px;

  display: flex;
  position: relative;
}

.GameImages img{
  width: 100%;
  height: 100%;
  border-radius: 7px;
  object-fit: cover;
  object-position: top;
}

.DifficultyLevel{
  position: absolute;
  bottom: 15px;
  left: 15px;

  border: 2px solid;

  padding: 5px 7px;
  border-radius: 7px;

  font-size: 13px;
  font-weight: 400;
}

.hard{
  border-color: #f59c9b;
  background-color: #B94A48;
  color: var(--white);
}

.medium{
  border-color: #eed5a6;
  background-color: #cb9e4a;
  color: var(--white);
}

.easy{
  border-color: #afedaa;
  background-color: rgb(36, 131, 36);
  color: var(--white);
}

.fullscreen-button{
  position: absolute;

  display: flex;
  align-items: center;
  justify-self: center;

  bottom: 15px;
  right: 15px;

  padding: 10px 10px;
  border-radius: 10px;
  
  color: var(--background-main);

  background-color: var(--white);

  border: none;

  transition: all 0.1s ease-in-out;
  box-shadow: 0 4px 0 0 #939393;
}

.fullscreen-button:hover {
  background-color: #b5b5b5;
}

.fullscreen-button:active {
  transform: translateY(4px);
  box-shadow: 0 0px 0 0 #939393;
}


@media (max-width: 630px) {
  .GameImages{
    height: 70vh;
  }
}