.Navbar {
  width: 100%;
}

.desktop-navbar{
  display: grid;
  grid-template-columns: auto 1fr auto; 
  align-items: start;
  gap: 20px; 
  width: 100%;
}

.mobile-navbar{
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  width: 100%;
}

.mobile-top{
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 10px;
}

.iconLogout{
  color: var(--white);
}

.discord-icon{
  width: 20px;
  height: 20px;
}

.nav-middle{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  transition: width 0.3s ease;
}

.left-nav-container{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  
  gap: 13px;
}

.anonymous-user{
  color: #616568;
}


@media (max-width: 1000px){
  .desktop-navbar{
    display: flex;
    justify-content: space-between;
  }
}

@media (max-width: 930px){
  .nav-middle{
    width: 100%;
  }

  .mobile-top{
    justify-content: space-between;
  }
}