.gameEndedContainer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  height: 15%;
}

.gamePlayAgain{
  border-radius: 10px;
  gap: 10px;
  width: calc(100% - 4px);

  display: flex;
  align-items: center;
  justify-content: center;

  border: 2px solid rgb(255, 255, 255);

  padding: 10px;

  background-color: var(--ice-blue);
  color: var(--white);
  transition: all 0.3s ease-in-out;
  box-shadow: 0 4px 0 0 #939393;
}

.gamePlayAgain:active{
  transform: translateY(4px);
  box-shadow: 0 0px 0 0 #939393;
}

.gamePlayAgain:hover{
  background-color: #53ccff;
}

.gamePlayAgain p{
  font-size: 18px;
  font-weight: 600;
}

.end-text-container{
  gap: 7px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 10px;
  border-radius: 10px;
  color: var(--background-main);
  background-color: var(--white);
  transition: all 0.1s ease-in-out;
  box-shadow: 0 4px 0 0 #939393;
}

.end-text-container p{
  color: var(--background-main);
}

.gameEndedContainer img{
  height: 100%;
  max-height: 90px;
  max-width: 40%;
  aspect-ratio: 1/1;
  border-radius: 7px;
  object-fit: cover;
}

.gameEndedContainer span{
  font-weight: 700;
}

@media (max-width: 450px){
  .gameEndedContainer img{
    max-height: 60px;
  }

  .end-text-container p{
    font-size: 13px;
  }

  .gamePlayAgain p{
    font-size: 15px;
    font-weight: 600;
  }

  .gameEndedContainer svg{
    font-size: 30px;
  }
}