.LoginModal{
  position: absolute;
  
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(0, 0, 0, 0.6);

  width: 100vw;
  height: 100vh;
  margin-top: -30px;

  z-index: 5;
}

.LoginModal-container{
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 15px;

  background-color: var(--background-main);
  color: var(--white);
  padding: 20px;
  border-radius: 10px;

  width: 380px;

  box-shadow: 0px 0px 13px 3px rgba(0, 0, 0, 0.25)
}

.LoginModal-container h2{
  font-size: 25px;
  font-weight: 700;
}

.LoginModal-container h3{
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  background-color: var(--background-light);
  padding: 10px;
  border-radius: 7px;
}

.login-modal-separator{
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
}

hr{
  width: 100%;
}

.LoginModal-container input{
  background-color: var(--white);
  border: none;
  border-radius: 10px;
  width: 100%;
  height: 50px;

  box-sizing: border-box;

  padding: 0px 10px;

  font-size: 15px;

  outline-color: var(--background-main);
}

.LoginModal-container p{
  font-weight: 300;
}

.login-create{
  border: none;
  background-color: unset;
  color: var(--white);
  font-size: 15px;

  font-weight: 500;
}

.google-button, .continue{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  width: 100%;
  padding: 0px 10px;
  height: 50px;
  border-radius: 10px;
  border: none;

  font-size: 15px;
  font-weight: 600;

  background-color: var(--white);
  color: var(--background-main);
  border: 2px solid transparent;
  transition: all 0.1s ease-in-out;
  box-shadow: 0 4px 0 0 #939393;
}

.google-button:hover, .continue:hover{
  background-color: #b5b5b5;
}

.google-button:active, .continue:active{
  transform: translateY(4px);
  box-shadow: 0 0px 0 0 #939393;
}

.google-icon{
  width: 20px;
  height: 20px;
}

.form{
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

@media (max-width: 500px) {
  .LoginModal-container{
    width: 100%;
    margin: 20px;
  }
}