.Gamemodes{
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 15px;

  background-color: var(--background-light);
  padding: 7px 0px 0px 0px;
  border-radius: 10px;

  min-height: 0;
}

.gamemodes-container{
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 12px;
  column-gap: 10px;

  border-radius: 10px;

  padding: 0px 7px 13px 7px;

  overflow-y: scroll;
  border-radius: 10px;

  height: 100%;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.gamemodes-title{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  color: var(--white);
  border-radius: 7px;

  padding-top: 5px;
}

.gamemodes-title h2{
  text-align: center;
  color: var(--white);
  font-size: 18px;
}

@media (max-width: 1350px){
  .gamemodes-container{
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 1300px){
  .gamemodes-container{
    padding-bottom: 5px;
  }

  .gamemodes-container{
    grid-template-columns: 1fr;
  }
}

@media (max-width: 800px){
  .Gamemodes{
    width: 100%;
  }

  .gamemodes-title h2{
    padding: 5px 0px;
  }

  .gamemodes-container{
    max-height: unset;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 480px){
  .gamemodes-container{
    display: flex;
  }
}