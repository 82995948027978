.Friends{
  display: flex;
  flex-direction: column;
  gap: 15px;
  background-color: var(--background-light);
  padding: 7px;
  border-radius: 10px;

  min-height: 0;
}

.friends-title{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  color: var(--white);
  border-radius: 7px;

  padding-top: 5px;
}

.friends-title h2{
  text-align: center;
  color: var(--white);
  font-size: 18px;
}

.friends-container{
  display: flex;
  flex-direction: column;
  gap: 7px;
  border-radius: 7px;

  height: 100%;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.no-friends{
  width: 100%;
  padding-bottom: 5px;
}

.friend-request{
  width: 100%;
  height: 35px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-bottom: 5px;
  gap: 7px;
}

.friend-request input{
  width: 100%;
  height: 100%;

  border-radius: 7px;
  padding-left: 5px;

  font-size: 15px;

  background-color: var(--white);
  color: var(--background-main);
  border: 2px solid transparent;
  transition: all 0.1s ease-in-out;
  box-shadow: 0 4px 0 0 #939393;
}

.friend-request button:disabled{
  background-color: #797979;
  box-shadow: 0 4px 0 0 #4e4e4e;
}

.friend-request button:disabled:hover{
  background-color: #797979;
}

.friend-request button:disabled:active{
  transform: unset;
  box-shadow: 0 4px 0 0 #4e4e4e;
  background-color: #797979;
}

.friend-request button{
  min-height: 35px;
  min-width: 35px;

  height: 100%;

  border-radius: 7px;
  border: none;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var(--white);
  color: var(--background-main);
  border: 2px solid transparent;
  transition: all 0.1s ease-in-out;
  box-shadow: 0 4px 0 0 #939393;
}

.friend-request button:hover{
  background-color: #b5b5b5;
}

.friend-request button:active{
  transform: translateY(4px);
  box-shadow: 0 0px 0 0 #939393;
}

.login-for-friends{
  background-color: var(--white);
  color: var(--background-main);

  padding: 7px;
  
  border: none;

  font-size: 15px;
  font-weight: 500;

  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;

  box-shadow: 0 4px 0 0 #939393;
  border-radius: 10px;

  margin-bottom: 3px;
}