h1{
  font-size: 30px;
  font-weight: 700;
  text-align: center;

  transition: all 0.5s ease;
}

@media (max-width: 850px) {
  h1{
    font-size: 4vw;
    padding-bottom: 20px;
  }
}

@media (max-width: 630px){
  h1{
    font-size: 30px;
  }
}