.Leaderboard{
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 7px;
  border-radius: 10px;
  background-color: var(--background-light);
  min-height: 0;
}

.selected{
  background-color: var(--background-light)!important;
  color: var(--white) !important;
  border: 2px solid rgb(216, 216, 216) !important;
  box-shadow: 0 4px 0 0 rgb(169, 169, 169) !important;

  font-weight: 500 !important;
}

.selected:hover{
  background-color: rgb(216, 216, 216) !important;
}

.selected:active{
  transform: translateY(4px);
  box-shadow: 0 0px 0 0 rgb(216, 216, 216) !important;
}

.leaderboard-title{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  color: var(--white);

  padding-top: 5px;
}

.leaderboard-title h2{
  text-align: center;
  color: var(--white);
  font-size: 18px;
}

.leaderboard-types{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
}

.leaderboard-types img{
  height: 20px;
  width: 20px;
}

.leaderboard-types button{
  border-radius: 7px;
  border: none;
  padding: 7px 7px;
  text-wrap: nowrap;

  width: 100%;

  font-weight: 400;
  font-size: 15px;

  background-color: var(--white);
  color: var(--background-main);
  border: 2px solid transparent;

  transition: all 0.3s ease-in-out;
  box-shadow: 0 4px 0 0 #939393;

  cursor: pointer;
}

.leaderboard-types button:hover{
  background-color: #b5b5b5;
}

.leaderboard-types button:active {
  transform: translateY(4px);
  box-shadow: 0 0px 0 0 #939393;
}

.leaderboard-members{
  display: flex;
  flex-direction: column;
  gap: 7px;
  border-radius: 7px;
  height: 100%;
  min-height: 0;

  overflow-y: scroll;

  scrollbar-width: none;
  -ms-overflow-style: none;
}

@media (max-width: 800px){
  .Leaderboard{
    width: 100%;
  }

  .leaderboard-types, .leaderboard-members{
    padding: 0px;
    background-color: unset;
  }
}
