.GameControlBar{
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 10%;

  justify-content: space-between;
  align-items: center;
}

.modalContainer{
  position: relative;
}

.skip-icon{
  width: 20px;
  height: 20px;
}

.GameControlBar .PointsStreak p{
  font-size: 25px;
  font-weight: 500;
  color: var(--white);
}

.GameControlBar .PointsStreak .levelDesign{
  font-size: 16px;
}

@media (max-width: 500px) {
  .GameControlBar .PointsStreak p{
    font-size: 20px;
    font-weight: 500;
    color: var(--white);
  }
  
  .GameControlBar .PointsStreak img{
    width: 20px;
    height: 20px;
  }
}