:root {
  --background-main: #25272A;
  --background-light: #37393C;
  --white: #fff;
  --black: #000;
  --success-green: #458847;
  --danger-red: #B94A48;
  --ice-blue: #00B2FF;
}

html, body{
  background-color: var(--background-main);
}

p{
  font-size: 15px;
}

p,a,ul,li,h1,h2,h3,h4{
  margin: 0;
  padding: 0;
  color: white;
}

input, textarea{
  border: none;
  margin: 0px;
  padding: 0px;
  font-family: inherit;
}

button{
  padding: 0;
  margin: 0;
  font-family: inherit;
  cursor: pointer;
}

body {
  margin: 0;
  font-family: 'Lexend', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-main);
}

* {
  box-sizing: border-box;
}