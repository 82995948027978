.BasicButton, .LogoutButton, .LoginButton, .SubmitButton, .DiscordButton {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  border-radius: 10px;

  border: none;

  transition: all 0.1s ease-in-out;
}

/* BASIC BUTTON */
.BasicButton{
  padding: 5px 12px;
  height: 40px;
  font-size: 15px;
  
  color: var(--background-main);
  background-color: var(--white);

  box-shadow: 0 4px 0 0 #939393;
}

.BasicButton:hover{
  background-color: #b5b5b5;
}

.BasicButton:active {
  transform: translateY(4px);
  box-shadow: 0 0px 0 0 #b5b5b5;
}

/* DISCORD BUTTON */
.DiscordButton{
  padding: 5px 12px;
  height: 40px;
  font-size: 15px;
  font-weight: 500;

  text-wrap: nowrap;
  
  color: var(--white);
  background-color: #5865F2;

  box-shadow: 0 4px 0 0 #38419e;
}

.DiscordButton:hover{
  background-color: #3e47b3;
}

.DiscordButton:active {
  transform: translateY(4px);
  box-shadow: 0 0px 0 0 #3e47b3;
}

/* SUBMIT BUTTON */
.SubmitButton{
  padding: 10px 12px;

  font-size: 15px;
  font-weight: 500;

  width: 100%;
  
  color: var(--white);
  background-color: #555555;

  box-shadow: 0 4px 0 0 #464646;
}

.SubmitButton:hover{
  background-color: rgb(77, 78, 82);
}

.SubmitButton:active {
  transform: translateY(4px);
  box-shadow: 0 0px 0 0 #464646;
}

/* LOGOUT BUTTON */
.LogoutButton{
  padding: 10px;
  font-size: 15px;
  font-weight: 500;

  width: 100%;
  
  color: var(--white);
  background-color: var(--danger-red);

  box-shadow: 0 4px 0 0 #742929;
}

.LogoutButton:hover{
  background-color: #923434;
}

.LogoutButton:active {
  transform: translateY(4px);
  box-shadow: 0 0px 0 0 #742929;
}


/* LOGIN BUTTON */
.LoginButton{
  padding: 7px;
  font-size: 15px;
  font-weight: 400;

  text-wrap: nowrap;
  background-color: var(--success-green);
  color: var(--white);

  box-shadow: 0 4px 0 0 #385839;
}

.LoginButton:hover{
  background-color: #39743b;
}

.LoginButton:active{
  transform: translateY(4px);
  box-shadow: 0 0px 0 0 #39743b;
}

@media (max-width: 850px){
  .BasicButton{
    font-size: 12px;
  }
}
