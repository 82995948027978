.Body-desktop{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 0;
}

.centered{
  justify-content: center;
}

.Body-mobile{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 30px;
  height: 100%;
}

.double-container{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 27%;
  height: 100%;
  min-height: 0;
  
  gap: 20px;

  transition: transform 0.4s ease-in-out;
}

.right{
  animation: slideInRight 0.4s ease;
}

.left{
  animation: slideInLeft 0.4s ease;
}

@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-left {
  transform: translateX(-100%); /* Slide out to the left */
}

.slide-right {
  transform: translateX(100%); /* Slide out to the right */
}

@media (max-width: 800px){
  .Body-desktop{
    gap: 25px;
  }

  .double-container{
    width: 100%;
  }
}