.StartGame{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  padding: 15px;
  background-color: var(--white);
  border-radius: 10px;

  width: 100%;
}

.startgame-section{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.startgame-section:last-of-type{
  padding-bottom: 10px;
}

.StartGame span{
  font-weight: 700;
}

.StartGame h3{
  color: var(--background-main);
  font-size: 18px;
  font-weight: 700;
}

.settings-container{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.stats-container{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  padding: 12px 12px;
  border-radius: 10px;

  background-color: var(--background-light);
}

.stats-container p{
  color: var(--white);
}

@media (max-width: 800px) {
  .StartGame{
    width: calc(100% - 20px);
    padding: 10px 10px 15px 10px;
  }
}